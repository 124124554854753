import React from 'react'
import Icon from '../components/Text/Icon'

export default function Projects() {
    return (
        <div className='flex flex-col items-center justify-center w-full h-screen'>
            <Icon icon='balloon' className='-mt-36 text-9xl text-light animate-bounce' />
            <h1 className='mt-12 text-3xl text-light'>Coming Soon!</h1>
        </div>
    )
}
